
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuSheetDesktop',

  mixins: [
    megaMenuMixin,
  ],

  props: {
    navbarId: {
      type: Number,
      required: true,
    },

    isHomepage: {
      type: Boolean,
      required: true,
    },

    version: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    activeNodeId: null,
    setItemTimeout: null,
  }),

  computed: {
    parentItems () {
      return this.megaCategory
        ?.filter(({ parentId }) => parentId === this.navbarId)
    },

    parentItemsIds () {
      return this.parentItems.map(({ nodeId }) => nodeId)
    },

    activeParent () {
      return this.megaCategory.find(({ nodeId }) => nodeId === this.activeNodeId) || {}
    },
  },

  watch: {
    navbarId () {
      if (this.activeNodeId && this.parentItemsIds.includes(this.activeNodeId)) {
        return
      }

      if (this.version === 1) {
        return
      }

      this.setParentItem(this.parentItems[0]?.nodeId, 0)
    },
  },

  methods: {
    setParentItem (nodeId, timeout = 150) {
      this.clearSetParentItem()
      this.setItemTimeout = setTimeout(() => {
        this.activeNodeId = nodeId
      }, timeout)
    },

    clearSetParentItem () {
      clearTimeout(this.setItemTimeout)
    },

    clearParentItem () {
      if (this.version > 1 && this.isHomepage) {
        this.setParentItem(null)
      }
    },

    onMenuClick () {
      this.clearParentItem()
      this.$emit('toggle-navbar', false)
    },
  },
}
