
export default {
  name: 'MegaMenuSheetDesktopLink',

  props: {
    to: {
      type: [String, Object, Boolean],
      required: true,
    },
  },
}
