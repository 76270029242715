
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'HomePageMegaMenu',

  mixins: [
    megaMenuMixin,
  ],

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    catalogCategoryId () {
      return this.navbarItems.find(item => item.hasChildren)?.nodeId
    },
  },
}
